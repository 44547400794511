<template>
  <v-navigation-drawer :color="ant" permanent absolute class="" app>
    <v-list-item>
      <v-list-item-content>
        <v-img :src="require('@/assets/img/Genius.png')" alt="Essay Genius" />
      </v-list-item-content>
    </v-list-item>

    <v-list>
      <v-list-item  dense link to="/order/draft" class="mb-2 mt-5">
        <v-list-item-icon>
          <v-icon>mdi-plus-circle</v-icon>
          <!-- <i class="fas fa-plus-circle"></i> -->
        </v-list-item-icon>

        <v-list-item-content class="ml-n4">
          <v-list-item-title>Place Order</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    <v-divider></v-divider>
         <v-list-item dense link to="/dashboard/customer/profile/view" >
          <v-list-item-avatar size="30">
            <v-img
            :src="getSession.avatar"
            v-if="getSession.avatar"
            >
            </v-img>
            <v-icon v-else> mdi-account </v-icon>
          </v-list-item-avatar>

          <v-list-item-content class="ml-n2">
            <v-list-item-title>{{ getSession.username }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-icon>mdi-chevron-right</v-icon>
          </v-list-item-action>
        </v-list-item>

        <v-list-item dense link to="/dashboard/finance/transaction" >
          <v-list-item-icon>
            <v-icon> mdi-credit-card </v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title> Balance </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <span> ${{ getRunningBalance }}</span>
          </v-list-item-action>
        </v-list-item>

        <v-list-item dense link to="/dashboard/notifications/" @click="notification = 0">
          <v-list-item-icon>
            <v-icon> mdi-bell </v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title> Notifications </v-list-item-title>
          </v-list-item-content>

          <v-list-item-action>
            <v-badge inline :content="notification" :value="notification"> </v-badge>
          </v-list-item-action>
        </v-list-item>

       <v-divider> </v-divider>

        <v-list-item dense link to="/dashboard/settings/" >
          <v-list-item-icon>
            <v-icon> mdi-tools </v-icon>
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title> Settings </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item dense link to="/dashboard/orders/open" >
          <v-list-item-icon>
            <v-icon> mdi-pencil-box-outline </v-icon>
            <!-- <i class="far fa-edit"></i> -->
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title> My Orders </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link dense to="/dashboard/writers/all">
          <v-list-item-icon>
            <v-icon> mdi-account-multiple-plus </v-icon>
            <!-- <i class="far fa-edit"></i> -->
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title> Writers</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      <v-list-item link  @click.native="passChat">
        <v-list-item-icon>
          <v-icon> mdi-view-dashboard </v-icon>
        </v-list-item-icon>

        <v-list-item-content class="ml-n4">
          <v-list-item-title> Chat </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-list dense nav>
        <v-divider></v-divider>
        <v-list-item link @click="logout">
          <v-list-item-icon>
            <v-icon right dark>mdi-logout</v-icon>
            <!-- <i class="far fa-edit"></i> -->
          </v-list-item-icon>

          <v-list-item-content class="ml-n4">
            <v-list-item-title> Logout </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex";
import connection from '@/socket/utils';

export default {
  name: "NavigationDrawer",
  data() {
    return {
      items: [
        { title: "Dashboard", icon: "mdi-view-dashboard" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" }
      ],
      right: null,
      ant: "#273142",
      chatStatus: false,
      socket: null,
      notification: '',
      runningBalance: '',
    };
  },
  computed: {
    ...mapGetters(["getChatStatus", 'getSession', 'getNotification', 'getRunningBalance']),
  },
  watch: {
    getNotification(val) {
      this.notification = val;
    },
    getRunningBalance(val) {
      this.runningBalance = val;
    },
  },
  async mounted() {
    connection.connect();
    this.socket = connection.subscribe(`notification:${this.getSession.id}`, this.handleMessageAdd)
    this.notification = this.getNotification;
    await this.getBalance();
  }, 
  methods: {
    ...mapMutations(['setChatStatus', 'setWholeNotification', 'setNotification']),
    ...mapActions(['clearCache', 'refreshAuthToken', 'getBalance']),
    passChat() {
      const data = {
        status: !this.getChatStatus.status,
        room: null
      };
      this.setChatStatus(data);
    },
    chatopen(data) {
      this.chatStatus = data.status;
    },
    async logout() {
      await this.clearCache();
      this.$router.push("/auth/login");
    },
    handleMessageAdd(message) {
      const { type } = message;
      switch (type) {
        case 'balance':
          // this.refreshAuthToken();
          this.getBalance();
          break;
        case 'notification':
          this.setWholeNotification(1);
          this.$popNotification.play();
          break;
        default:
          break;
      }
    }
  },
};
</script>

<style scoped>
.ant {
  background: #273142;
}

a {
  text-decoration: none;
}
</style>
